.SlideBody > * {
  margin: 0px;
}

.SlideBody {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.SlideBody {
  color: var(--mui-palette-primary-main);
  font-family: "Poppins";
  font-size: "1rem";
  font-weight: "normal";
  line-height: "1.5";
  letter-spacing: "0.07813rem";
}

.SlideBody ul {
  padding-left: 4px;
  list-style-position: inside;
  line-height: 1.5;
}
.SlideBody ul li {
  list-style-type: none;
  position: relative;
  padding-left: 4px;
}

.SlideBody ul li::before {
  content: "•";
  position: absolute;
  left: -0.6em;
}

.SlideBody :is(a, a:link, a:visited, a:focus, a:hover, a:active) {
  color: var(--mui-palette-primary-main);
  text-decoration: underline;
  cursor: pointer;
}

.SlideBody p :is(a, a:link, a:visited, a:focus, a:hover, a:active) {
  color: var(--mui-palette-text-main);
  text-decoration: underline;
  cursor: pointer;
}

.control-dots {
  pointer-events: none;
}
