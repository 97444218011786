#digital-human {
  overscroll-behavior: none;
  text-align: left;
  --desktop-header-height: 97px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#digital-human::-webkit-scrollbar {
  display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;700&family=Poppins:wght@400;600;700&family=Roboto&display=swap");
